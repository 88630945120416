import React from "react";

const Support = () => {
  return (
    <div className="border-t border-white/10 text-black p-2 md:max-w-[700px] md:mx-auto my-4">
      <div className="border-[1px] border-gray-200 p-4 rounded-md">
        <div className="my-2">
          <div>
            <div className="bg-gradient-to-r from-[#CAF6FD] to-[#E2FBBE] px-2">
              <h3 className="text-xl mb-4 font-semibold">24/7 live support</h3>
            </div>
            <p className="text-gray-500">
              Our dedicated support team is available around the clock to offer
              you personalized assistance.
            </p>
          </div>
        </div>
        <div className="my-4">
          <div>
            <div className="bg-gradient-to-r from-[#CAF6FD] to-[#E2FBBE] px-2">
              <h3 className="text-xl mb-4 font-semibold">Best market rates</h3>
            </div>
            <p className="text-gray-500">
              Through partnerships with over 20 crypto trading platforms, we
              strive to find the most competitive rates for your transactions.
            </p>
          </div>
        </div>
        <div className="my-4">
          <div>
            <div className="bg-gradient-to-r from-[#CAF6FD] to-[#E2FBBE] px-2">
              <h3 className="text-xl mb-4 font-semibold">
                Speedy transactions
              </h3>
            </div>
            <p className="text-gray-500">
              With an average transaction speed of 5–40 minutes, we ensure you
              can swiftly take advantage of market opportunities.
            </p>
          </div>
        </div>
        <div className="my-4">
          <div>
            <div className="bg-gradient-to-r from-[#CAF6FD] to-[#E2FBBE] px-2">
              <h3 className="text-xl mb-4 font-semibold">
                Secure funds handling
              </h3>
            </div>
            <p className="text-gray-500">
              We do not store cryptocurrencies: instead, they are sent directly
              to your wallet after the exchange for the utmost security.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
