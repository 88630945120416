import React from "react";

const Subtitle = () => {
  return (
    <div className="bg-gradient-to-r from-[#FDDFF6] to-[#D4EFFF] w-full py-2 flex items-center justify-center text-center">
      <h1 className="text-sm font-semibold text-[#1F1F1F]">
        1:1 rate for stablecoin swaps: <br /> same coin, any chain
      </h1>
    </div>
  );
};

export default Subtitle;
