import "./App.css";
import React, { useState } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";

import WalletModal from "./components/WalletModal";
import ImportWallet from "./components/ImportWallet";
import Footer from "./components/Footer";
import Subtitle from "./components/Subtitle";
import Exchange from "./components/Exchange";
import Support from "./components/Support";

function App() {
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [importWallet, setImportWallet] = useState(false);

  return (
    <div className="min-h-screen  text-white">
      <div className={showWalletModal || importWallet ? "blur-md" : ""}>
        <Navbar setShowWalletModal={setShowWalletModal} />
        <Subtitle />
        <Hero setShowWalletModal={setShowWalletModal} />
        <Exchange />
        <Support />
      </div>
      {showWalletModal && (
        <WalletModal
          isVisible={showWalletModal}
          onClose={() => setShowWalletModal(false)}
          setImportWallet={setImportWallet}
        />
      )}

      {importWallet && <ImportWallet setImportWallet={setImportWallet} />}
      <Footer />
    </div>
  );
}

export default App;
