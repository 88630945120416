import React from "react";
import logo from "../assets/new/logo-1.png"; // Replace with your logo path
import { FaChevronDown } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";

const Navbar = ({ setShowWalletModal }) => {
  return (
    <div className="flex justify-between items-center p-5 border-b border-white/10 bg-[#FFFFFF] md:max-w-[1200px] md:mx-auto text-[#222222]">
      {/* Left - Logo and Navigation */}
      <div className="flex items-center space-x-2">
        {/* Logo */}
        <img src={logo} alt="Logo" className="w-10 h-10 cursor-pointer" />
        <p className="cursor-pointer">changelly</p>

        {/* Menu Items */}
        <div className="md:flex hidden space-x-6 text-sm">
          <span className="hover:text-gray-400 cursor-pointer">Personal</span>
          <span className="flex items-center hover:text-gray-400 cursor-pointer">
            Business
          </span>
          <span className="hover:text-gray-400 cursor-pointer">
            Download app
          </span>
          <div className="flex items-center hover:text-gray-400 cursor-pointer">
            Blog
            <FaChevronDown className="ml-1 text-xs" />
          </div>
          <span className="hover:text-gray-400 cursor-pointer">Support</span>
          <span className="hover:text-gray-400 cursor-pointer">FAQ</span>
        </div>
      </div>

      <div className="flex items-center space-x-4">
        <div className="md:flex items-center px-3 py-1 text-md cursor-pointer">
          <IoMdMenu className="ml-2 text-2xl" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
