import logo32 from "../assets/new/logo-32.svg";
import logo33 from "../assets/new/logo-33.svg";
import logo34 from "../assets/new/logo-34.svg";

const Exchange = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-[#F3FAEC] py-6">
      <div className="flex flex-col items-center space-y-1">
        <p className="text-3xl text-black font-semibold">Exchange any crypto</p>
        <p className="text-3xl text-[#34D333] font-semibold">securly</p>
      </div>
      <p className="text-[#7F8082] text-md text-center my-4">
        Exchange Bitcoin and 500+ altcoins in a fast, simple, and secure way.
      </p>
      <div className="flex items-center justify-between space-x-2">
        <img src={logo32} alt="" />
        <img src={logo33} alt="" />
        <img src={logo34} alt="" />
      </div>
    </div>
  );
};

export default Exchange;
