import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#FAFAFA] border-t border-white/10 text-black py-8 px-6 ">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-6xl md:max-w-[1200px] md:mx-auto">
        <div>
          <h3 className="text-md mb-4">Personal</h3>
          <h3 className="text-md mb-4">Company</h3>
          <h3 className="text-md mb-4">Support</h3>
          <h3 className="text-md mb-4">For partners</h3>
          <h3 className="text-md mb-4">Legal</h3>
          <h3 className="text-md mb-4">Buy</h3>
          <h3 className="text-md mb-4">Exchange</h3>
          <h3 className="text-md mb-4">Exchange pairs</h3>
          <h3 className="text-md mb-4">Cryptocurrency Prices</h3>
        </div>
      </div>
      <div className="md:max-w-[1200px] md:mx-auto">
        <h4 className="text-xs mb-2">&copy; Changelly 2015-2024</h4>
      </div>
    </footer>
  );
};

export default Footer;
