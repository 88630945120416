import React from "react";
import logo31 from "../assets/new/logo-31.png";
import logo32 from "../assets/new/logo-32.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdSwapVert } from "react-icons/md";

const Hero = ({ setShowWalletModal }) => {
  return (
    <div className="relative bg-[#FFFFFF] text-black py-4 px-2">
      <div className="flex flex-col max-w-[500px] mx-auto">
        <div className="my-2">
          <div className="flex items-center justify-between px-2">
            <p
              onClick={() => {
                setShowWalletModal(true);
              }}
              className=" cursor-pointer"
            >
              Exchange
            </p>
            <p
              onClick={() => {
                setShowWalletModal(true);
              }}
              className=" cursor-pointer text-gray-500"
            >
              Buy
            </p>
            <p
              onClick={() => {
                setShowWalletModal(true);
              }}
              className=" cursor-pointer text-gray-500"
            >
              Sell
            </p>
            <p
              onClick={() => {
                setShowWalletModal(true);
              }}
              className=" cursor-pointer text-gray-500"
            >
              DeFi
            </p>
          </div>
        </div>

        {/* box */}
        <div className="my-4">
          <div className="">
            <div className="bg-[#F6F7F8] flex items-center justify-between p-4 rounded-md">
              <div
                className="text-sm space-y-1 cursor-pointer"
                onClick={() => {
                  setShowWalletModal(true);
                }}
              >
                <p className="text-xs text-gray-500">You send</p>
                <div className="flex items-center space-x-1">
                  <img src={logo31} alt="" className="w-5" />
                  <p>BTC</p>
                  <MdKeyboardArrowDown className="text-gray-500" />
                </div>
                <div className="flex items-center space-x-1">
                  <p className="text-xs text-gray-500">Bitcoin</p>
                  <p className="text-sm bg-[#FED8A5] p-[2px]">BTC</p>
                </div>
              </div>
              <div className="mr-1">
                <p className="text-lg font-semibold">0.1</p>
              </div>
            </div>
            <div className="flex items-center justify-center -mt-4 -mb-4">
              <MdSwapVert className="text-4xl text-gray-500 border-2 rounded-full bg-white" />
            </div>
            {/* last */}
            <div className="bg-[#F6F7F8] flex items-center justify-between p-4 rounded-md">
              <div
                className="text-sm space-y-1 cursor-pointer"
                onClick={() => {
                  setShowWalletModal(true);
                }}
              >
                <p className="text-xs text-gray-500">You get</p>
                <div className="flex items-center space-x-1">
                  <img src={logo32} alt="" className="w-5" />
                  <p>ETH</p>
                  <MdKeyboardArrowDown className="text-gray-500" />
                </div>
                <div className="flex items-center space-x-1">
                  <p className="text-xs text-gray-500">Ethereum</p>
                  <p className="text-sm bg-[#FED8A5] p-[2px]">ETH</p>
                </div>
              </div>
              <div className="mr-1">
                <p className="text-lg font-semibold">~2.74329562</p>
              </div>
            </div>

            <div className="flex items-center justify-center mt-4 w-full">
              <button
                onClick={() => {
                  setShowWalletModal(true);
                }}
                className="px-2 py-3 bg-[#B1FF8C] rounded-full font-medium text-md w-full text-[#1F1F1F]"
              >
                Connect Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
